import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Plasma 5.18 släppt: `}<a parentName="li" {...{
          "href": "https://kde.org/announcements/plasma-5.18.0"
        }}>{`https://kde.org/announcements/plasma-5.18.0`}</a></li>
      <li parentName="ul">{`Firefox 73 släppt: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/73.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/73.0/releasenotes/`}</a></li>
      <li parentName="ul">{`GeForce Now ute ur beta: `}<a parentName="li" {...{
          "href": "https://www.nvidia.com/en-us/geforce-now/"
        }}>{`https://www.nvidia.com/en-us/geforce-now/`}</a></li>
      <li parentName="ul">{`Proton 5.0: `}<a parentName="li" {...{
          "href": "https://github.com/ValveSoftware/Proton/releases/tag/proton-5.0-1"
        }}>{`https://github.com/ValveSoftware/Proton/releases/tag/proton-5.0-1`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Windows startmeny gick sönder: `}<a parentName="li" {...{
          "href": "https://www.forbes.com/sites/gordonkelly/2020/02/09/windows-10-warning-serious-failure-provokes-questions-and-anger/#10dfc0a434f4"
        }}>{`https://www.forbes.com/sites/gordonkelly/2020/02/09/windows-10-warning-serious-failure-provokes-questions-and-anger/#10dfc0a434f4`}</a></li>
      <li parentName="ul">{`Windows 10 startmeny-reklam för Edge: `}<a parentName="li" {...{
          "href": "https://www.windowslatest.com/2020/02/08/windows-10-start-menu-edge-ad/"
        }}>{`https://www.windowslatest.com/2020/02/08/windows-10-start-menu-edge-ad/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`asknot: `}<a parentName="li" {...{
          "href": "https://github.com/jdm/asknot"
        }}>{`https://github.com/jdm/asknot`}</a><ul parentName="li">
          <li parentName="ul">{`exempel: `}<a parentName="li" {...{
              "href": "https://www.plasma-mobile.org/findyourway"
            }}>{`https://www.plasma-mobile.org/findyourway`}</a></li>
        </ul></li>
      <li parentName="ul">{`Open Textbook Library: `}<a parentName="li" {...{
          "href": "https://open.umn.edu/opentextbooks"
        }}>{`https://open.umn.edu/opentextbooks`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Multi-boot: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=ZL1GREqoqx8&feature=youtu.be"
        }}>{`https://www.youtube.com/watch?v=ZL1GREqoqx8&feature=youtu.be`}</a></li>
      <li parentName="ul">{`Alex har installerat en distro och drar av skyddsplasten LIVE`}</li>
    </ul>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`600MB uppdateringar efter 6 dagar`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Tipsa om Battles`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Cursed Intro - Dark Dramatic Epic Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Mystical Sting" av Alexander Nakarada`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      